// @ts-nocheck
import React from "react";
import {
    Spinner,
} from 'react-bootstrap';
import Auth from '@aws-amplify/auth';

import { format } from 'date-fns';

import config from '../config';
import DateFnsUtils from '@date-io/date-fns';
import Alert from '@material-ui/lab/Alert';

import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import {
    Chip,
    Grid,
} from '@material-ui/core';

import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const todayDate = new Date();

const columns = [
    { field: 'host', headerName: 'Hostname', width: 230 },
    { field: 'ip', headerName: 'IP Address', width: 170 },
    { field: 'PlatformType', headerName: 'OS', width: 130 },
    { field: 'PlatformName', headerName: 'Name', width: 130 },
    { field: 'PlatformVersion', headerName: 'Version', width: 130 },
    {
        field: 'security',
        headerName: 'Security Update',
        flex: 1,
        renderCell: (params) => (
            <DisplayWarning label={params.value} />
        ),
    },
    {
        field: 'others',
        headerName: 'Others update',
        flex: 1,
        renderCell: (params) => (
            <Chip label={params.value} />
        ),
    }
];

function DisplayWarning(props) {
    var value = props.label;

    var color = 'primary';
    if (value < 1) {
        color = '';
    }
    if (value > 100) {
        color = 'secondary';
    }

    return (
        <Chip label={value} color={color} />
    );
}

class PkgList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            selectedDate: new Date(),
        };
    }

    componentDidMount() {
        const baseUrl = config.api.baseUrl;
        console.log("Get new report from API Gateway: " + ToS3DateFormat(this.state.selectedDate));

        Auth.currentSession().then(
            data => {
                let secretKey = data.idToken.jwtToken

                let query = '/get_patches?date=' + ToS3DateFormat(this.state.selectedDate) + '&report=report';
                // ${baseUrl}
                fetch(`${baseUrl}${query}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': secretKey,
                    },
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setState({
                                isLoaded: true,
                                items: result,
                            });
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    )
            }
        );

    }


    checkDate(newDate) {
        // ToDo use setState()
        this.state.selectedDate = newDate;
        this.state.error = null;
        this.componentDidMount();
    }

    render() {
        const { error, isLoaded, items } = this.state;

        if (error) {
            return <div>
                <Grid container justify="center" style={{ padding: 20 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Select report date"
                            value={this.state.selectedDate}
                            onChange={(e) => this.checkDate(e)}
                            format={"dd/MM/yyyy"}
                            maxDate={todayDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid container justify="center" style={{ padding: 20 }}>
                    <Alert severity="error"><strong>Rreport not found</strong> — {error.message}</Alert>
                </Grid>

            </div>;
        } else if (!isLoaded) {
            return (
                <div>
                    <Grid container justify="center" style={{ padding: 20 }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                label="Select report date"
                                value={this.state.selectedDate}
                                onChange={(e) => this.checkDate(e)}
                                format={"dd/MM/yyyy"}
                                maxDate={todayDate}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid container justify="center" style={{ padding: 20 }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Grid>
                </div>
            );
        }


        return (
            <div>
                <Grid container justify="center" style={{ padding: 20 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Select report date"
                            value={this.state.selectedDate}
                            onChange={(e) => this.checkDate(e)}
                            format={"dd/MM/yyyy"}
                            maxDate={todayDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <div style={{ width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: GridToolbar,
                        }}

                        density="compact"
                        pageSize={10} rowsPerPageOptions={[10, 25, 50, 100]}
                        autoHeight getRowId={(r) => r.host} rows={items} columns={columns} />
                </div>
            </div>
        );
    }
}

function ToS3DateFormat(date) {
    return format(date, 'yyyy/MM/dd')
}

export default PkgList;