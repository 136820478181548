// @ts-nocheck
import React from "react";
import {
    Spinner,
} from 'react-bootstrap';
import Auth from '@aws-amplify/auth';
import Alert from '@material-ui/lab/Alert';

import config from '../config';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';

import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import {
    Grid,
} from '@material-ui/core';

import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const todayDate = new Date();

const columnsPkgs = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'section', headerName: 'Section', width: 150 },
    { field: 'severity', headerName: 'Severity', width: 150 },
]

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});


function Row(props) {
    console.log(props);
    //return(<div></div>);
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.host}
                </TableCell>
                <TableCell align="right">{row.ip}</TableCell>
                <TableCell align="right">{row.PlatformName}</TableCell>
                <TableCell align="right">{row.PlatformType}</TableCell>
                <TableCell align="right">{row.PlatformVersion}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">Packages</Typography>
                            <DisplayPackages pkgs={row.pkgs} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};


function DisplayPackages(props) {
    var pkgs = props.pkgs;

    return (
        <div>
            <div style={{ width: '100%' }}>
                <DataGrid
                    components={{
                        Toolbar: GridToolbar,
                    }}

                    density="compact"
                    pageSize={10} rowsPerPageOptions={[10, 25, 50, 100]}
                    autoHeight getRowId={(r) => r.name} rows={pkgs} columns={columnsPkgs} />
            </div>
        </div>
    );
}

class PkgListDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            selectedDate: new Date(),
        };
    }

    componentDidMount() {
        const baseUrl = config.api.baseUrl;
        console.log("Get new report from API Gateway: " + ToS3DateFormat(this.state.selectedDate));

        Auth.currentSession().then(
            data => {
                let secretKey = data.idToken.jwtToken

                let query = '/get_patches?date=' + ToS3DateFormat(this.state.selectedDate) + '&report=report_complete';
                // ${baseUrl}
                fetch(`${baseUrl}${query}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': secretKey,
                    },
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setState({
                                isLoaded: true,
                                items: result,
                            });
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    )
            }
        );

    }


    checkDate(newDate) {
        // ToDo use setState()
        this.state.selectedDate = newDate;
        this.state.error = null;
        this.componentDidMount();
    }

    render() {
        const { error, isLoaded, items } = this.state;

        if (error) {
            return <div>
                <Grid container justify="center" style={{ padding: 20 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Select report date"
                            value={this.state.selectedDate}
                            onChange={(e) => this.checkDate(e)}
                            format={"dd/MM/yyyy"}
                            maxDate={todayDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid container justify="center" style={{ padding: 20 }}>
                    <Alert severity="error"><strong>Rreport not found</strong> — {error.message}</Alert>
                </Grid>
            </div>;
        } else if (!isLoaded) {
            return (
                <div>
                    <Grid container justify="center" style={{ padding: 20 }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                label="Select report date"
                                value={this.state.selectedDate}
                                onChange={(e) => this.checkDate(e)}
                                format={"dd/MM/yyyy"}
                                maxDate={todayDate}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid container justify="center" style={{ padding: 20 }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Grid>
                </div>
            );
        }


        return (
            <div>
                <Grid container justify="center" style={{ padding: 20 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Select report date"
                            value={this.state.selectedDate}
                            onChange={(e) => this.checkDate(e)}
                            format={"dd/MM/yyyy"}
                            maxDate={todayDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Hostname</TableCell>
                                <TableCell align="right">IP Address</TableCell>
                                <TableCell align="right">OS</TableCell>
                                <TableCell align="right">Name</TableCell>
                                <TableCell align="right">Version</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((row) => (
                                <Row key={row.host} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

function ToS3DateFormat(date) {
    return format(date, 'yyyy/MM/dd')
}

export default PkgListDetail;