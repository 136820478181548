import logo from './logo512.png';
import './App.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import Auth from '@aws-amplify/auth';
import PkgList from './screens/PkgList';
import PkgListDetail from './screens/PkgListDetail';
import { Navbar, Nav } from 'react-bootstrap';

import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function AppRouter() {

  return (
    <Router>
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="#home">
          <img
            alt=""
            src="/logo512.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          <span className="px-2">Digital Solution</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Nav className="mr-auto">
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/pkgs">Infrastructure Report</Nav.Link>
            <Nav.Link href="/pkgs/detail">Packages details</Nav.Link>   
            <AmplifySignOut />
          </Navbar.Collapse>
        </Nav>
      </Navbar>

      {/*
        A <Switch> looks through all its children <Route>
        elements and renders the first one whose path
        matches the current URL. Use a <Switch> any time
        you have multiple routes, but you want only one
        of them to render at a time
      */}
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/pkgs">
          <PkgList />
        </Route>
        <Route exact path="/pkgs/detail">
          <PkgListDetail />
        </Route>
      </Switch>
    </Router>
  );
}

function Home() {
  return (
    <App />
  );
}

function App() {

  //Auth.currentSession().then(data => console.log(data.accessToken));
  //Auth.currentSession().then(data => console.log(data.accessToken.jwtToken));
  Auth.currentSession().then(data => console.log(data.idToken.jwtToken));

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-title">
          Digital Solution | IT Operations (ITO) | criticalcase
        </p>
      </header>
    </div>
  );
}

export default withAuthenticator(AppRouter)